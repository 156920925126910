var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "linkding-outer-wrapper" }, [
    _vm.links
      ? _c("div", { staticClass: "linkding-wrapper" }, [
          _c(
            "ul",
            _vm._l(_vm.links, function (link) {
              return _c("li", { key: link.id, staticClass: "lingkding-link" }, [
                _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: link.description,
                          expression: "link.description",
                        },
                      ],
                      staticClass: "linktext",
                    },
                    [_vm._v(_vm._s(link.title))]
                  ),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }